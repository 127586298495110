import { APIError } from "scalingo/lib/errors";
import { ReviewApp } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { App } from "@/lib/scalingo/modifiers/apps";
import { manualReviewApp } from "@/store/review-apps";

import type { ComponentPublicInstance } from "vue";

export class CreateReviewAppHandler extends ActionHandler<ReviewApp> {
  get keyPath(): string {
    const error = this.operation?.error as APIError;

    if (this.isError && error?.status === 402) {
      return "reviewApps.free-trial";
    }

    return "reviewApps.manual";
  }

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("failure", () => {
      this.notify("error", { owner: this.app.owner.username });
    });
  }

  async submit(pullRequestId: string): Promise<void> {
    this.follow(await manualReviewApp(this.$store, pullRequestId));
  }
}
