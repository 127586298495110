export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "some": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("itemsShowed")), " out of ", _interpolate(_named("itemTotal")), " item"]), _normalize([_interpolate(_named("itemsShowed")), " out of ", _interpolate(_named("itemTotal")), " items"])])},
        "all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 item"]), _normalize([_interpolate(_named("itemsShowed")), " item"]), _normalize([_interpolate(_named("itemsShowed")), " items"])])}
      },
      "fr": {
        "some": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("itemsShowed")), " sur ", _interpolate(_named("itemTotal")), " élément"]), _normalize([_interpolate(_named("itemsShowed")), " sur ", _interpolate(_named("itemTotal")), " éléments"])])},
        "all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 élément"]), _normalize([_interpolate(_named("itemsShowed")), " élément"]), _normalize([_interpolate(_named("itemsShowed")), " éléments"])])}
      }
    }
  })
}
