<template>
  <ViewComponent> </ViewComponent>
</template>

<script>
import { defineComponent, onBeforeMount } from "vue";
import { useRouter } from "vue-router";

import ViewComponent from "@/components/views/Collaborators.vue";
import { Routes } from "@/router/names";
import { userIsAdminOrHasFlag } from "@/store/user";
import { currentUser } from "@/stores/user";

export default defineComponent({
  name: "Collaborators",
  components: { ViewComponent },
  setup() {
    const router = useRouter();

    onBeforeMount(() => {
      if (!userIsAdminOrHasFlag(currentUser(), "employee")) {
        router.push({ name: Routes.Welcome });
      }
    });
  },
});
</script>
