<template>
  <h2>Collaborators Page</h2>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Collaborators",
});
</script>
