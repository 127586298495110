<template>
  <div class="text-scale-5 text-sm">
    <span v-if="allShown">
      {{ $tc("all", itemsShowed, { itemsShowed }) }}
    </span>
    <span v-else>
      {{ $tc("some", itemTotal, { itemsShowed, itemTotal }) }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CountPager",
  props: {
    itemsShowed: [Number, String],
    itemTotal: Number,
  },
  computed: {
    allShown() {
      return this.itemsShowed === this.itemTotal;
    },
  },
});
</script>

<i18n>
en:
  some: "{itemsShowed} out of {itemTotal} item | {itemsShowed} out of {itemTotal} items"
  all: "0 item | {itemsShowed} item | {itemsShowed} items"
fr:
  some: "{itemsShowed} sur {itemTotal} élément | {itemsShowed} sur {itemTotal} éléments"
  all: "0 élément | {itemsShowed} élément | {itemsShowed} éléments"
</i18n>
