<template>
  <div class="w-full">
    <div v-if="!windowTooSmall">
      <div class="flex flex-row justify-between">
        <div v-if="searchable" class="mb-4 w-4/5">
          <div
            v-if="loading"
            class="relative h-8 animate-pulse bg-scale-2 rounded w-full md:w-1/2 lg:w-1/3"
          ></div>
          <div
            v-else
            class="relative border border-scale-2 rounded w-full md:w-1/2 lg:w-1/3"
          >
            <input
              v-model="searchTerm"
              class="form-input bg-scale-0 rounded block w-full pr-10 sm:text-sm sm:leading-5 placeholder-scale-6 px-3 py-1 focus:ring-1 focus:ring-color-3 focus:outline-none text-scale-6"
              :placeholder="$t('placeholder')"
            />

            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 8.30362L1.08334 10.9735C0.964246 11.106 0.970115 11.3087 1.09667 11.4341L1.56334 11.9013C1.62593 11.9645 1.71112 12 1.8 12C1.88888 12 1.97408 11.9645 2.03667 11.9013L4.70334 9.2314C4.77709 9.1575 4.84403 9.07707 4.90334 8.99111L5.40334 8.32364C6.23063 8.98506 7.25797 9.34518 8.31667 9.34487C10.4983 9.35245 12.394 7.84573 12.8815 5.71674C13.369 3.58774 12.3183 1.40466 10.3513 0.459942C8.38427 -0.484772 6.02602 0.0610084 4.67267 1.77417C3.31932 3.48732 3.33205 5.91064 4.70334 7.60945L4.03667 8.07C3.93256 8.13672 3.83638 8.21509 3.75 8.30362ZM11.65 4.67255C11.65 6.51572 10.1576 8.0099 8.31668 8.0099C7.43262 8.0099 6.58478 7.65829 5.95966 7.03241C5.33453 6.40654 4.98335 5.55767 4.98335 4.67255C4.98335 2.82938 6.47573 1.3352 8.31668 1.3352C10.1576 1.3352 11.65 2.82938 11.65 4.67255Z"
                  fill="#7B8794"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          v-if="secretiveCells"
          class="flex flex-row items-center space-x-2 mb-4 w-1/5 justify-end"
          :title="$t('toggleSecretiveMode')"
        >
          <small-toggle v-model="secretive"></small-toggle>
          <div
            class="text-scale-7 mx-1 cursor-pointer h-6"
            @click="secretive = !secretive"
          >
            <span>{{ $t("blurring") }}</span>
          </div>
        </div>
      </div>

      <BaseTable
        v-if="loading"
        :layout="layout"
        :headers="headers"
        :items="loadingItems"
        :showHeaders="showHeaders"
        :striped="striped"
        :smallPadding="smallPadding"
      >
        <template #header>
          <div></div>
        </template>

        <template #item>
          <slot name="loading">
            <div
              class="relative h-2 my-2 animate-pulse bg-scale-2 rounded w-full md:w-1/2 lg:w-1/3"
            ></div>
          </slot>
        </template>
      </BaseTable>

      <BaseTable
        v-else
        :layout="layout"
        :headers="headers"
        :items="paginatedItems"
        :striped="striped"
        :smallPadding="smallPadding"
      >
        <template v-if="!$slots.header" #header="{ header }">
          <template v-if="header.sortable">
            <a
              href="#"
              class="text-scale-10"
              @click.prevent="toggleSortDirection"
            >
              {{ header.text }}
              <span v-if="sortDirection === 'asc'">
                <font-awesome-icon icon="caret-down" />
              </span>
              <span v-if="sortDirection === 'desc'">
                <font-awesome-icon icon="caret-up" />
              </span>
            </a>
          </template>
          <template v-else>
            {{ header.text }}
            <span v-if="secretive && header.secretive">
              ({{ $t("headerNotice") }})</span
            >
          </template>
        </template>

        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" :secretive="secretive" />
        </template>
      </BaseTable>

      <slot
        name="bottomFiller"
        v-bind="{ remaining: perPage - paginatedItems?.length }"
      >
      </slot>

      <SCPagination
        v-if="showFooter"
        :kind="paginated ? 'pager' : 'more'"
        :hasMore="hasMore"
        :loading="fetchingMore"
        :items="filteredItems"
        :perPage="perPage"
        :currentPage="currentPage + 1"
        @pageChanged="(e) => (currentPage = e.page - 1)"
        @fetchMore="$emit('fetchMore')"
      />
    </div>
    <div v-else>
      <Alert styling="warning" :text="$t('tooSmall')">
        <template v-slot:icon><DangerSignGlyph /></template>
      </Alert>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import BaseTable from "@/components/atoms/tables/BaseTable.vue";
import SmallToggle from "@/components/atoms/toggles/SmallToggle.vue";
import Alert from "@/components/molecules/alerts/Alert.vue";
import SCPagination from "@/components/molecules/pagers/SCPagination.vue";

export function defaultSearchFn(row, search) {
  return !!Object.values(row).find((col) => {
    if (!col) return false;

    return (
      col.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !==
      -1
    );
  });
}

export default defineComponent({
  name: "SCTable",
  components: { SmallToggle, DangerSignGlyph, Alert, BaseTable, SCPagination },
  props: {
    ...BaseTable.props,
    searchable: {
      type: Boolean,
      default: false,
    },
    searchFn: {
      type: Function,
      default: defaultSearchFn,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    fetchingMore: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: [Number, String],
      default: 15,
    },
    startPage: {
      type: [Number, String],
      default: 0,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    sortBy: {
      type: String,
      default: null,
    },
    secretiveCells: {
      type: Boolean,
      default: false,
    },
    sortDirection: {
      type: String,
      default: "asc",
      validator(value) {
        return ["asc", "desc"].includes(value);
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    striped: {
      type: Boolean,
      default: true,
    },
    smallPadding: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["fetchMore"],
  data() {
    return {
      currentPage: 0,
      searchTerm: "",
      windowTooSmall: false,
      secretive: true,
    };
  },

  computed: {
    loadingItems() {
      return Array(this.perPage).fill({});
    },
    filteredItems() {
      if (this.searchable && this.searchTerm) {
        return this.items.filter((r) => this.searchFn(r, this.searchTerm));
      }

      return this.items;
    },
    paginatedItems() {
      if (this.paginated) {
        const start = this.currentPage * this.perPage;

        return this.filteredItems.slice(start, start + this.perPage);
      }

      return this.filteredItems;
    },
  },
  watch: {
    searchTerm(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.currentPage = 0;
      }
    },
  },
  created() {
    this.currentPage = this.startPage;
    this.checkWindowSize();
    window.addEventListener("resize", this.checkWindowSize);
  },
  unmounted() {
    window.removeEventListener("resize", this.checkWindowSize);
  },
  methods: {
    checkWindowSize() {
      // pv breakpoint
      if (window.innerWidth < 640) {
        this.windowTooSmall = true;
        return;
      }
      this.windowTooSmall = false;
    },
    toggleSortDirection() {
      if (this.sortDirection === "asc") {
        this.$emit("sortChanged", {
          sortBy: this.sortBy,
          sortDirection: "desc",
        });
      } else if (this.sortDirection === "desc") {
        this.$emit("sortChanged", {
          sortBy: this.sortBy,
          sortDirection: "asc",
        });
      }
    },
  },
});
</script>

<i18n>
en:
  tooSmall: "Window is too small to display this element. Turn your phone"
  placeholder: "Search"
  toggleSecretiveMode: "Hide values that may contain sensitive data such as passwords."
  headerNotice: "visible on hover"
  blurring: "Hide values"
fr:
  tooSmall: "L'affichage est trop petit pour cet élément. Tourner votre téléphone"
  placeholder: "Recherche"
  toggleSecretiveMode: "Cacher les valeurs qui peuvent contenir des données sensibles telles que des mots de passe."
  headerNotice: "visible au survol"
  blurring: "Cacher les valeurs"
</i18n>
